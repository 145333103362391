<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong>Reporte Ventas</strong>
          </CCardHeader>
          <CCardBody>

          <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
            <b-row class="justify-content-center">

                <b-col lg="6">
                  <b-form-group>
                    <label>Cliente: </label>
                    <v-select placeholder=" Todos " class="w-100" :filterable="false" label="text" v-model="client" @search="SearchClients" :options="clients"></v-select>
                    <small v-if="errors.id_client" class="form-text text-danger" >Selccione un cliente</small>
                  </b-form-group>
                </b-col>

                <b-col sm="2" >
                  <b-form-group label="Desde:">
                    <b-form-input type="date" v-model="report.from" ></b-form-input>
                    <small v-if="errors.from" class="form-text text-danger" >Seleccione una fecha</small>
                  </b-form-group>
                </b-col>

                <b-col sm="2" >
                  <b-form-group label="Hasta:">
                    <b-form-input type="date" v-model="report.to" ></b-form-input>
                    <small v-if="errors.to" class="form-text text-danger" >Seleccione una fecha</small>
                  </b-form-group>
                </b-col>

                <b-col sm="2" >
                  <b-form-group label="Sede:">
                    <b-form-select v-model="report.branch_office" :options="branch_office"></b-form-select>
                  </b-form-group>
                </b-col>


                <b-col sm="12" md="1">
                  <b-form-group>
                    <label for="">.</label>
                    <b-button class="form-control" type="submit" variant="primary"><i class="fas fa-search"></i></b-button>
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="1">
                  <b-form-group>
                    <label for="">.</label>
                    <b-button class="form-control" type="button" variant="success" @click="ExportExcel"><i class="fas fa-file-excel"></i></b-button>
                  </b-form-group>
                </b-col>

                

            </b-row>
          </b-form>


              <div class="table-responsive mt-3">
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th class="text-center" colspan="10">VENTAS</th>
                    </tr>
                    <tr>
                      <th class="text-center">#</th>
                      <th class="text-center">Fecha</th>
                      <th class="text-center">Tipo Doc.</th>
                      <th class="text-center">Numero</th>
                      
                      <th class="text-center">Nombres y Apellidos</th>
                      <th class="text-center">DNI</th>
                      <th class="text-center">V. Venta</th>
                      <th class="text-center">IGV</th>
                      <th class="text-center">Monto</th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr v-for="(item, it) in data_table" :key="it">
                      <td class="text-center">{{ it + 1 }}</td>
                      <td class="text-center">{{ item.date }}</td>
                      <td class="text-center">{{ item.type_invoice }}</td>
                      <td class="text-left">{{ item.number }}</td>
                      <td class="text-left">{{ item.client_name }}</td>
                      <td class="text-left">{{ item.client_document_number }}</td>
                      <td class="text-right">{{ item.base_amount }}</td> 
                      <td class="text-right">{{ item.igv }}</td> 
                      <td class="text-right">{{ item.total }}</td> 
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="6" class="text-right">TOTALES</td>
                      <td class="text-right">{{ total.base_amount }}</td>
                      <td class="text-right">{{ total.igv }}</td>
                      <td class="text-right">{{ total.total }}</td>
                    </tr>
                  </tfoot>
                
                </table>
              </div>
  
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>

<style>
        table {
            width: 100%;
            border-collapse: collapse;
            overflow-x: auto; 
        }
    </style>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import CodeToName from "@/assets/js/CodeToName";
var moment = require("moment");
import LoadingComponent from './../pages/Loading'

export default {
  name: "UsuarioList",
  components:{
    vSelect,
    LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'ReportSales',
      role:1,
      perPage: 15,
      currentPage: 1,
      rows: 0,
      data_table: [],
      total : {
        base_amount : '0.00',
        igv : '0.00',
        total : '0.00',
      },

      report:{
        id_user:'all',
        from:moment(new Date()).local().format("YYYY-MM-DD"),
        to:moment(new Date()).local().format("YYYY-MM-DD"),
        credit_type:'all',
        program_type:'all',
        branch_office:'all',
      },
      users : [],
      user: null,
      client: null,
      clients: [],
      credit_type:[],
      program_type:[],
      branch_office:[],
  
      errors:{
        id_user:false,
        to:false,
      }
    };
  },
  mounted() {
   this.ListUsers();
   this.ListBranchOffice();
  },
  methods: {
    ListUsers,
    ListProducts,
    ListProductsType,
    ListBranchOffice,
    Validate,
    Report,
    ExportExcel,
    SearchClients,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_establishment: function () {
      let id_establishment = window.localStorage.getItem("id_establishment");
      id_establishment = JSON.parse(je.decrypt(id_establishment));
      return id_establishment;
    },
  },
};

function SearchClients(search, loading) {
  
  let me = this;
   let url = this.url_base + "client/search";
   if (search !== "") {

    let data = {
      search : search
    };

     loading(true);
     axios({
       method: "POST",
       url: url,
       data: data,
       headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
     }).then(function (response) {
           me.clients = response.data.result;
           loading(false);
     })
   }
}

function ListUsers() {
  let me = this;
  let url = me.url_base + "active-users";
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.users = response.data.result;
      }
    });
}


function ListProducts() {
  let me = this;
  let url = me.url_base + "data/get-product-actives";
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.credit_type = [{ value: 'all', text: '- Todos -'}];
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
          me.credit_type.push({ value: element.id_product, text:  element.name });
        }
      }
    });
}


function ListProductsType() {
  let me = this;
  let url = me.url_base + "data/get-product-type-actives";
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.program_type = [{ value: 'all', text: '- Todos -'}];
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
          me.program_type.push({ value: element.id_product_type, text:  element.name });
        }
      }
    });
}

function ListBranchOffice() {
  let me = this;
  let url = me.url_base + "active-branch-office";
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.branch_office = [{ value: 'all', text: '- Todos -'}];
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
          me.branch_office.push({ value: element.id_branch_office, text:  element.name });
        }
      }
    });
}

function ExportExcel() {  
  this.errors.from = this.report.from.length == 0 ? true : false;
  this.errors.to = this.report.to.length == 0 ? true : false;

 if (this.errors.from == true) { return false; }
 if (this.errors.to == true) { return false; }
  let id_client = this.client == null ? 'all':this.client.value;
  let me = this;
  let url = me.url_base + "excel/report-sales/"+id_client+"/"+me.report.from+"/"+me.report.to+"/"+me.report.branch_office;
  window.open(url,'_blank');
}

function Validate() {

  this.errors.from = this.report.from.length == 0 ? true : false;
  this.errors.to = this.report.to.length == 0 ? true : false;
  if (this.errors.from == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.to == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  
  this.Report();
}


function Report() {
  let me = this;
  me.report.id_client = me.client == null ? 'all': me.client.value;
  let data = me.report;
  let url = this.url_base + "report/sales";
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { token: this.token, module: this.module, role: 1,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.data_table = response.data.result.sales;
        me.total = response.data.result.total;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
      me.isLoading = false;
    })
     .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}





</script>
